<template>
  <section class="section" id="details">
    <div class="section__wrapper">
      <div class="image__wrapper">
        <img
          class="desktop-img"
          src="https://tessewing.com/images/sec2-edited.png"
          alt="Tess Ewing and Joe Keller looking cool for their wedding"
        />
        <img
          class="mobile-img"
          src="https://tessewing.com/images/sec2-bkgd.jpg"
          alt="Tess Ewing and Joe Keller looking cool for their wedding"
        />
        <div class="image__frame">
          <img
            class="image__frame-bottom"
            src="https://tessewing.com/images/Purple_floral_wreath-bottom.png"
            role="presentation"
          />
        </div>
      </div>
      <div class="content__wrapper">
        <div class="content">
          <h2>Awbury Arboretum</h2>
          <p class="description">1 Awbury Road,<br />Philadelphia, PA 19138</p>
          <ul class="event-list">
            <li><strong>4:00</strong> Guest Arrival</li>
            <li><strong>4:30</strong> Ceremony</li>
            <li><strong>5:00</strong> Cocktail Hour</li>
            <li><strong>6:00</strong> Dinner</li>
            <li><strong>7:00</strong> Dancing</li>
            <li><strong>10:00</strong> After Party @ Hotel</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section__wrapper {
  .content__wrapper {
    @media screen and (min-width: 769px) {
      background-image: url(https://tessewing.com/images/pink-wc-bg.png);
    }
    @media screen and (max-width: 768px) {
      background-image: url(https://tessewing.com/images/pink-wc-bg-mobile1.png);
    }
  }
}
</style>
