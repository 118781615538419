<template>
  <section class="section" id="welcome">
    <div class="section__wrapper">
      <div class="image__wrapper">
        <img
          src="https://tessewing.com/images/sec1-bkgd.jpg"
          alt="Tess and Joe on the couch"
        />
        <div class="image__frame">
          <img
            class="image__frame-top"
            src="https://tessewing.com/images/Purple_floral_wreath-top.png"
            role="presentation"
          />
          <img
            class="image__frame-bottom"
            src="https://tessewing.com/images/Purple_floral_wreath-bottom.png"
            role="presentation"
          />
        </div>
      </div>
      <div class="content__wrapper">
        <div class="content">
          <h1>Tess & Joe are getting married</h1>
          <p class="date">April 27, 2024</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section__wrapper {
  flex-wrap: wrap;
  justify-content: center;
  .image__wrapper {
    width: 100%;
    height: 80%;
    position: relative;
    > img {
      width: 80%;
      height: 80%;
      object-fit: cover;
      object-position: top center;
      border-top-left-radius: 10rem;
      border-bottom-right-radius: 10rem;
    }
    @media screen and (max-width: 768px) {
      height: 50%;
      > img {
        border-top-left-radius: 5rem;
        border-bottom-right-radius: 5rem;
      }
    }
  }
  .content__wrapper {
    text-align: center;
    @media screen and (max-width: 768px) {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(https://tessewing.com/images/pink-wc-bg.png);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1024px) {
    .image__wrapper > img {
      width: 90%;
      height: 90%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 1rem 2rem 1rem;
    flex-direction: column;
    justify-content: flex-start;
    .content__wrapper {
      flex-grow: 1;
    }
    > img {
      border-top-left-radius: 10rem;
      border-bottom-right-radius: 10rem;
    }
  }
}
</style>
