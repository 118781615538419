<template>
  <section class="section" id="travel">
    <div class="section__wrapper">
      <div class="content__wrapper">
        <div class="content">
          <h2>Courtyard City Avenue</h2>
          <p class="description">
            4100 Presidential Blvd,<br />Philadelphia, PA 19131
          </p>
          <a
            class="button"
            href="https://www.marriott.com/events/start.mi?id=1687204350541&key=GRP"
            target="_blank"
            >Book In Our Block Now</a
          >
          <p class="small">
            Shuttle transportation between the hotel and the venue will be
            provided before the ceremony and after the reception.
          </p>
        </div>
      </div>
      <div class="image__wrapper">
        <img
          class="desktop-img"
          src="https://tessewing.com/images/sec3-edited.png"
          alt="Tess Ewing and Joe Keller looking cool for their wedding"
        />
        <img
          class="mobile-img"
          src="https://tessewing.com/images/sec3-bkgd.jpg"
          alt="Tess Ewing and Joe Keller looking cool for their wedding"
        />
        <div class="image__frame">
          <img
            class="image__frame-bottom"
            src="https://tessewing.com/images/Purple_floral_wreath-bottom.png"
            role="presentation"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
$purple: #645a6d;
$blue: #476c89;
.section__wrapper {
  .content__wrapper {
    @media screen and (min-width: 769px) {
      background-image: url(https://tessewing.com/images/pink-wc-bg2.png);
      .content {
        max-width: 600px;
      }
    }
    @media screen and (max-width: 768px) {
      background-image: url(https://tessewing.com/images/pink-wc-bg-mobile1.png);
      .small {
        font-size: 0.875rem;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .image__wrapper {
      order: 1;
    }
    .content__wrapper {
      order: 2;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .small {
      text-align: center;
    }
  }
}
.button {
  background: $purple;
  color: #fff;
  padding: 1.5rem 2rem;
  transition: all 0.3s;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  font-weight: 500;
  &:hover {
    background: $blue;
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
}
</style>
