<template>
  <section class="section" id="faq">
    <div class="section__wrapper">
      <div class="content__wrapper">
        <div class="content">
          <h2>FAQ</h2>
          <div class="accordion">
            <div v-for="(faq, index) in faqs" :key="faq.id" class="tab">
              <input
                type="radio"
                name="accordion-1"
                :id="'cb' + index"
                :checked="index === 0 ? true : false"
              />
              <label :for="'cb' + index" class="tab__label">
                {{ faq.question }}
              </label>
              <div class="tab__content">
                <p v-html="faq.answer" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="image__wrapper">
        <img
          class="desktop-img"
          src="https://tessewing.com/images/sec4-edited.png"
          alt="Tess Ewing and Joe Keller looking cool for their wedding"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FaqSection",
  data() {
    return {
      faqs: [
        {
          question: "How are you both so attractive?",
          answer: "It truly is a mystery.",
        },
        {
          question: "Will children be welcome at the wedding?",
          answer:
            "We love your kids, but we want to celebrate with just you! Take a night off of parenting and relax :)",
        },
        {
          question: "Can I take photos during the ceremony?",
          answer:
            "We love that you want to capture our moment, but we've hired the talented <a href='https://chloedawson.com/' target='_blank'>Chloe Dawson</a> to do just that! Please keep your phones away during the ceremony and just enjoy the moment.",
        },
        {
          question: "What is the dress code?",
          answer:
            'We advise garden party attire! Think "having tea in the secret garden."',
        },
        {
          question: "Where do I park at the arboretum?",
          answer:
            "There will be a parking attendant onsite to guide you when you arrive. After you park, there is a short walk to the Francis Cope house. If you need accessible parking, please reach out to us so we can reserve a spot for you.",
        },
        {
          question: "What time should we arrive?",
          answer:
            "We advise arriving around 4pm. Light refreshments will be served. The ceremony will begin promptly at 4:30pm.",
        },
        {
          question:
            "Will transportation be provided between the hotel and the venue?",
          answer:
            "Yes! We've reserved a small bus to make rounds before the ceremony and after the reception. The first pick-up time from the hotel will be 3:15pm, and the first pick-up time from the venue will be 9pm.",
        },
        {
          question: "What hotels are nearby?",
          answer:
            "We have reserved a block of rooms at the Courtyard on City Avenue, which you can reserve by clicking <a href='https://www.marriott.com/events/start.mi?id=1687204350541&key=GRP'>here</a>. However, there are a number of other hotels in the area! <a href='https://www.porticoawbury.com/hotels'>Here are some</a> recommended by our venue.",
        },
        {
          question: "Are you doing anything after the reception?",
          answer:
            "Yes! After the reception officially ends at 10pm, we will be gathering at the bar at Courtyard hotel for more drinks. The bar will be open until 2am.",
        },
        {
          question: "Are you doing anything the morning after?",
          answer:
            "Yes! We plan on hosting a brunch at the Courtyard the following Sunday morning. More details to come, but please let us know if you're interested in attending.",
        },
        {
          question:
            "Who made that beautiful illustration of the venue on your invitation?",
          answer:
            "That would be the incredibly talented Dani Leon! Be sure to <a href='https://www.instagram.com/d.a.n.i.l.e.o.n/' target='_blank'>check out her work</a>.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$purple: #645a6d;
$blue: #476c89;
section {
  @media screen and (min-width: 1025px) {
    .content__wrapper {
      min-width: 66%;
      background-image: url(https://tessewing.com/images/pink-wc-bg3.png);
      .content {
        max-width: 700px;
      }
    }
    .image__wrapper {
      max-width: 33%;
    }
  }
  @media screen and (max-width: 1024px) {
    .image__wrapper {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
    &.section#faq .section__wrapper .content__wrapper {
      justify-content: flex-start;
    }
    .tab__label,
    .tab__content p {
      padding-bottom: 0;
    }
  }
  h2 {
    letter-spacing: 3px;
    text-align: center;
  }
  p,
  label {
    line-height: 1.3;
  }
  :deep(a) {
    color: $blue;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
