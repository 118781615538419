/* eslint-disable no-undef */
console.log("sup nerds");

$(document).ready(function () {
  const container = $(".container");
  const mainNav = $(".main-nav");
  const mainNavItems = mainNav.find("li");
  // const mobileNav = $(".mobile-nav");
  const mobileToggle = $("#mobile-toggle");

  function addNavBar() {
    var navWidth = mainNav.outerWidth();
    // var navHeight = mobileNav.outerHeight();
    var width = $(window).width();
    if (width > 1024) {
      container.css("padding-right", navWidth);
      container.css("margin-top", "");
      container.css("height", "");
      mainNav.removeClass("hidden");
    } else {
      container.css("padding-right", "");
      // container.css("margin-top", navHeight);
      // container.css("height", "calc(100vh - "+navHeight+"px)");
      mainNav.addClass("hidden");
    }
  }

  addNavBar();
  $(window).resize(function () {
    addNavBar();
  });

  function toggleMobileNav() {
    mainNav.toggleClass("hidden");
    container.toggleClass("mobile-nav-open");
  }

  mobileToggle.click(function () {
    toggleMobileNav();
  });

  mainNavItems.each(function () {
    $(this).click(function () {
      toggleMobileNav();
    });
  });

  container.click(function (e) {
    var parent = $(e.target).parent();
    if (
      container.hasClass("mobile-nav-open") &&
      e.target.id !== "navigation" &&
      parent.attr("id") !== "mobile-toggle"
    ) {
      toggleMobileNav();
    }
  });

  const crew = $("#crew");
  const crewTeams = crew.find(".crew-team");
  const crewTeamGrids = crew.find(".crew-team__grid");

  function deselectRadio(button, e) {
    const input = button.prev("input");
    if (input.is(":checked")) {
      e.preventDefault();
      input.prop("checked", false);
    }
  }

  crewTeams.each(function () {
    const crewTeamButtons = $(this).find(".tab__label");
    crewTeamButtons.each(function () {
      $(this).click(function (e) {
        deselectRadio($(this), e);
      });
    });
  });
  crewTeamGrids.each(function () {
    const crewButtons = $(this).find(".crew-member .crew-more__label");
    crewButtons.each(function () {
      $(this).click(function (e) {
        deselectRadio($(this), e);
      });
    });
  });

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const intersecting = entry.isIntersecting;
        let section = entry.target.id;
        let sectionLink = document.getElementById(section + "-link");
        if (intersecting) {
          $(".main-nav ul li").children().removeClass("active");
          sectionLink.classList.add("active");
        } else {
          sectionLink.classList.remove("active");
        }
      });
    },
    { threshold: 0.5 }
  );
  const sections = document.querySelectorAll(".section");
  sections.forEach((section) => {
    observer.observe(section);
  });

  $(document).on("click", 'a[href^="#"]', function (event) {
    event.preventDefault();

    $("html, body").animate(
      {
        scrollTop: $($.attr(this, "href")).offset().top,
      },
      200
    );
  });
});
