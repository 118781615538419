<template>
  <section class="section" id="rsvp">
    <div class="section__wrapper">
      <div class="content__wrapper">
        <component
          :is="'script'"
          type="text/javascript"
          src="https://tessandjoegetmarried.rsvpify.com/embed"
        ></component>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
#rsvp {
  .section__wrapper {
    #rsvpify-wrapper {
      height: 100%;
    }
    iframe {
      min-height: 100% !important;
    }
  }
  .content__wrapper {
    width: 100%;
  }
}
</style>
