<template>
  <section class="section" id="cheers">
    <div class="section__wrapper">
      <div class="content__wrapper">
        <div class="content">
          <p class="more-details">
            More details to come.<br />We can't wait to celebrate with you!
          </p>
        </div>
      </div>
    </div>
    <div class="copyright">
      All photography by
      <a href="https://chloedawson.com/" target="_blank">Chloe Dawson</a>.
    </div>
  </section>
</template>

<style lang="scss" scoped>
$purple: #645a6d;
$blue: #476c89;
section {
  background-image: url(https://tessewing.com/images/sec3-bkgd.jpg);
  z-index: 20;
  position: relative;
  .section__wrapper {
    padding: 10rem 5rem;
    width: 40%;
  }
  @media screen and (max-width: 1024px) {
    background-image: url(https://tessewing.com/images/sec3-bkgd-mobile.jpg);
    .section__wrapper {
      width: 100%;
      padding: 4rem 3rem;
      justify-content: center;
      .more-details {
        padding: 2rem;
        border-radius: 1rem;
        background-color: #ffffff99;
      }
    }
    .content__wrapper {
      display: flex;
      align-items: flex-end;
    }
  }
  .more-details {
    color: $blue;
    font-family: "Old Standard TT";
    font-size: 2.5em;
    text-shadow: 0px 2px 40px #ffffff59;
    text-align: center;
  }

  .copyright {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.85rem;
    text-shadow: 0px 2px 15px #00000099;
    position: absolute;
    bottom: 2rem;
    width: 100%;
    z-index: 21;
    text-align: center;
    a {
      color: #fff;
    }
  }
}
</style>
