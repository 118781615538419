<template>
  <nav class="main-nav" id="navigation">
    <ul>
      <li v-for="(section, index) in sections" :key="section.id">
        <a
          :id="section.href + '-link'"
          :href="'#' + section.href"
          :class="{ active: index === 0 }"
        >
          <span>{{ section.name }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MainNav",
  data() {
    return {
      sections: [
        {
          href: "welcome",
          name: "Welcome",
        },
        {
          href: "details",
          name: "Details",
        },
        {
          href: "travel",
          name: "Travel",
        },
        {
          href: "rsvp",
          name: "RSVP",
        },
        {
          href: "faq",
          name: "FAQ",
        },
        {
          href: "crew",
          name: "Our Crew",
        },
        {
          href: "registry",
          name: "Registry",
        },
        {
          href: "cheers",
          name: "Cheers",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$purple: #645a6d;
$blue: #476c89;
.main-nav {
  top: 0;
  right: 0;
  position: fixed;
  z-index: 200;
  padding: 4rem 2rem;
  margin: 0 auto;
  text-align: center;
  transition: all 0.3s ease-out;
  height: 100%;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    gap: 2rem;
    li {
      list-style: none;
      display: inline-block;
      padding: 10px;
      a {
        text-decoration: none;
        span {
          color: $purple;
          font-size: 1em;
          transition: all 0.3s ease-out;
        }
        &:after {
          content: "";
          display: inline-block;
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 50%;
          background-color: $purple;
          margin-left: 1rem;
          transition: all 0.3s ease-out;
          position: relative;
        }
        &:hover span {
          color: $blue;
        }
        &.active {
          span {
            border-bottom: 2px solid $blue;
            font-size: 1.2em;
            color: $blue;
          }
          &:after {
            margin-right: -0.125rem;
            width: 0.75rem;
            height: 0.75rem;
            background-color: $blue;
            z-index: 2;
          }
        }
      }
    }
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 0.25rem;
      height: 93%;
      top: 4%;
      background-color: $purple;
      margin-right: 0.75rem;
      border-radius: 1rem;
    }
  }
  &.hidden {
    right: -100%;
  }
  @media screen and (max-width: 1024px) {
    background-color: #fff;
  }
}
</style>
