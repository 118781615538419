<template>
  <section class="section" id="crew">
    <div class="section__wrapper">
      <div class="content__wrapper">
        <div class="content">
          <h2>Our Crew</h2>
          <p>
            We're incredibly lucky to have such a wonderful group of people to
            support us during this process. We'd love for you to take a moment
            to get to know them.
          </p>

          <div class="crew-grid">
            <div
              v-for="(team, index) in teams"
              :key="team.id"
              class="crew-team"
              :id="team.slug"
            >
              <input
                type="radio"
                name="accordion-crew-team"
                :id="'crew-' + team.slug"
                :checked="index === 0 ? true : false"
              />
              <label :for="'crew-' + team.slug" class="tab__label"
                ><span class="crew-team-title">{{ team.name }}</span></label
              >
              <span class="crew-team-title">{{ team.name }}</span>

              <div class="crew-team__grid">
                <div
                  v-for="member in team.members"
                  :key="member.id"
                  class="crew-member"
                >
                  <img :src="member.image" :alt="member.alt" />
                  <input
                    type="radio"
                    :name="'accordion-' + team.slug"
                    :id="'crew-' + member.firstName"
                  />
                  <label
                    :for="'crew-' + member.firstName"
                    class="crew-more__label"
                    >+</label
                  >
                  <span class="crew-name">{{ member.fullName }}</span>
                  <span class="crew-title">{{ member.title }}</span>
                  <div class="crew-description">
                    <img :src="member.image" :alt="member.alt" />
                    <p>{{ member.bio }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainNav",
  data() {
    return {
      teams: [
        {
          name: "Team Bride",
          slug: "team-bride",
          members: [
            {
              fullName: "Nicole Livengood",
              firstName: "nikki",
              title: "Maid of Honor",
              bio: "Nikki has been Tess's best friend since the 9th grade when she introduced the wonder that is Degrassi to her. Kavinskys forever!",
              image: "https://tessewing.com/images/nikki.jpg",
              alt: "Nikki looking beautiful",
            },
            {
              fullName: "Stephanie Ewing",
              firstName: "steph",
              title: "Bridesmaid",
              bio: "As Tess's oldest sister, Stephanie has provided support, love, laughs, housing, and advice that only the eldest daughter can provide.",
              image: "https://tessewing.com/images/steph.jpg",
              alt: "Steph looking beautiful",
            },
            {
              fullName: "Shannon Frelak",
              firstName: "shannon",
              title: "Bridesmaid",
              bio: "Shannon has been Tess's partner in crime and hijinx since birth. Things shared include: clothing, makeup, colored hair, interest in music.",
              image: "https://tessewing.com/images/shannon.jpg",
              alt: "Shannon looking beautiful",
            },
            {
              fullName: "Ryan Ewing",
              firstName: "ryan",
              title: "Bridesman",
              bio: 'Ryan has filled the role of "big brother" flawlessly for Tess. Forever providing goofiness, tough love, and guidance whenever needed.',
              image: "https://tessewing.com/images/ryan.jpg",
              alt: "Ryan looking handsome",
            },
            {
              fullName: "Kat Fesen",
              firstName: "kat",
              title: "Bridesmaid",
              bio: "Kat is Tess's favorite person to loudly agree with. Be it puppies, politics, or faerie smut, they're bound to share their opinions with each other.",
              image: "https://tessewing.com/images/kat.jpg",
              alt: "Kat looking beautiful",
            },
            {
              fullName: "Jackie Kontis",
              firstName: "jackie",
              title: "Bridesmaid",
              bio: "Jackie is Tess's twin as far as life events are concerned. They've been through it all: from sharing a boyfriend to having an itchy nose.",
              image: "https://tessewing.com/images/jackie.jpg",
              alt: "Jackie looking beautiful",
            },
          ],
        },
        {
          name: "Team Union",
          slug: "team-union",
          members: [
            {
              fullName: "Emilie Glick",
              firstName: "emilie",
              title: "Officiant",
              bio: "Emilie has been Tess's friend since kindergarten, and quickly became Joe's friend upon meeting. They are both honored to have someone so special lead their ceremony.",
              image: "https://tessewing.com/images/emilie.jpg",
              alt: "Emilie looking beautiful",
            },
          ],
        },
        {
          name: "Team Groom",
          slug: "team-groom",
          members: [
            {
              fullName: "Kristin Fox",
              firstName: "kristin",
              title: "Best Woman",
              bio: "Well known to make bad jokes and poke fun, this loveable lady is the life of the party (even if we party at home). Has a mean zinger, and a meaner cocktail recipe!",
              image: "https://tessewing.com/images/kristin.jpg",
              alt: "Kristin looking beautiful",
            },
            {
              fullName: "David Keller",
              firstName: "dave",
              title: "Groomsman",
              bio: "Always up for a whole hearted discussion or a whole house renovation, Dave is the man with the plan. So happy that he plans to be a groomsman!",
              image: "https://tessewing.com/images/dave.jpg",
              alt: "Dave looking handsome",
            },
            {
              fullName: "Tom Peduto",
              firstName: "tom",
              title: "Groomsman",
              bio: "A world traveler and world class foodie. Whether it's gaming or soccer, our interests always seem to align, including choosing the best year to get married!",
              image: "https://tessewing.com/images/tom2.jpg",
              alt: "Tom looking handsome",
            },
            {
              fullName: "Nick Mayo",
              firstName: "nick",
              title: "Groomsman",
              bio: "Always appreciative of the goofy side, so no wonder he got along with me. Whether in NYC or Fishtown, the good times are always there. I just know he'll elevate this wedding as well!",
              image: "https://tessewing.com/images/nick2.jpg",
              alt: "Nick looking handsome",
            },
            {
              fullName: "Stuart Rial",
              firstName: "stu",
              title: "Groomsman",
              bio: "With the voice of an angel, and hair like it too, he always lights up a room. The only one allowed to wear cowboy boots to the wedding, hope he doesn't outshine us on the big day!",
              image: "https://tessewing.com/images/stu.jpg",
              alt: "Stu looking handsome",
            },
            {
              fullName: "Jim Baranello",
              firstName: "jim",
              title: "Groomsman",
              bio: "New dad, but an old friend. Jim is always there for the ones he cares about, no wonder why he'll be there for our big day!",
              image: "https://tessewing.com/images/jim.jpg",
              alt: "Jim looking handsome",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$purple: #645a6d;
$blue: #476c89;
$pink: #e8cbe2;
section {
  text-align: center;
  padding: 1rem 3rem 2rem;
  @media screen and (max-width: 768px) {
    padding: 1rem 1rem 2rem;
    overflow: hidden;
    .section__wrapper {
      padding: 1rem 1rem 2rem;
    }
    .content > p {
      font-size: 0.875rem;
      line-height: 1.3;
    }
  }
  .content__wrapper {
    width: 100%;
  }
  .content > p {
    line-height: 1.4;
  }
  .crew-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: 2fr 1fr 2fr;
    margin-top: 2rem;
    width: 100%;
    .crew-team {
      width: 100%;
      > input {
        position: absolute;
        opacity: 0;
        z-index: -1;
      }
      > label {
        display: none;
      }
      .crew-team__grid {
        display: grid;
        gap: 0.75rem;
        grid-template-columns: repeat(2, 1fr);
      }
      &#team-union {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .crew-team__grid {
          grid-template-columns: 1fr;
          width: 100%;
        }
      }
      .crew-team-title {
        font-size: 2.5rem;
        font-family: "Ms Madi";
        color: $blue;
      }
    }
    .crew-member {
      text-align: center;
      line-height: 1.4;
      position: relative;
      width: 100%;
      background-image: url(https://tessewing.com/images/pink-wc-bg2.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center bottom;
      padding-bottom: 1rem;
      overflow: hidden;
      input {
        position: absolute;
        opacity: 0;
        z-index: -1;
        &:checked {
          ~ .crew-description {
            bottom: 0;
            opacity: 1;
            pointer-events: auto;
          }
          ~ .crew-more__label {
            transform: rotate(45deg);
          }
        }
      }
      .crew-more__label {
        z-index: 10;
        position: absolute;
        background-color: $purple;
        color: #fff;
        display: flex;
        line-height: 1;
        padding: 0.5rem;
        right: 1rem;
        bottom: 0.75rem;
        border-radius: 50%;
        transition: all 0.3s;
        user-select: none;
        cursor: pointer;
        width: 2rem;
        justify-content: center;
      }
      img {
        border-radius: 50%;
        border: 2px solid white;
        object-fit: cover;
        max-width: 150px;
        aspect-ratio: 1/1;
      }
      .crew-name,
      .crew-title {
        display: block;
      }
      .crew-name {
        font-weight: bold;
        margin-bottom: 0.125rem;
      }
      .crew-name,
      .crew-title {
        display: block;
      }
      .crew-title {
        font-size: 0.875rem;
        line-height: 1;
      }
      .crew-description {
        max-width: 182px;
        font-size: 0.875rem;
        display: flex;
        position: absolute;
        background-color: #fff;
        color: $blue;
        bottom: -100%;
        opacity: 0;
        pointer-events: none;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        border-radius: 0.75rem;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        transition: all 0.3s;
        border: 2px solid $pink;
        img {
          display: none;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 0;
      .crew-team {
        position: relative;
        > .crew-team-title {
          display: none;
        }
        label {
          display: flex;
          padding: 0;
          width: 100%;
          .crew-team-title {
            display: flex;
            border-bottom: 2px solid $pink;
            justify-content: flex-start;
            width: 100%;
            padding-bottom: 1rem;
            font-weight: normal;
          }
        }
        input:checked {
          ~ .crew-team__grid {
            bottom: 0;
            opacity: 1;
            max-height: 100rem;
            margin: 1rem 0;
          }
          + .tab__label::after {
            transform: rotate(270deg);
          }
        }
        > label::after {
          z-index: 10;
          position: absolute;
          background-color: $purple;
          color: #fff;
          display: flex;
          line-height: 1;
          padding: 0.5rem;
          right: 0;
          top: -0.25rem;
          border-radius: 50%;
          transition: all 0.3s;
          cursor: pointer;
          justify-content: center;
        }
        .crew-team__grid {
          opacity: 0;
          max-height: 0rem;
          transition: all 0.3s;
          grid-template-columns: repeat(3, 1fr);
          border-bottom: 2px solid $pink;
          padding-bottom: 1rem;
        }
        &#team-union .crew-team__grid .crew-member {
          width: 33%;
        }
      }
      .crew-member {
        img {
          max-width: 100px;
        }
        .crew-description {
          flex-direction: column;
          max-width: 242px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .crew-team {
        input:checked {
          ~ label .crew-team-title {
            font-size: 2rem;
          }
        }
        .crew-team__grid {
          position: relative;
          grid-template-columns: 1fr;
        }
        .crew-team-title {
          transition: all 0.3s;
          font-size: 1.5rem;
        }
        &#team-union .crew-team__grid .crew-member {
          width: 100%;
        }
      }
      .crew-member {
        text-align: left;
        position: static;
        background-image: none;
        display: grid;
        grid-template-columns: 2rem auto;
        column-gap: 1rem;
        padding-bottom: 0;
        .crew-more__label {
          position: static;
          grid-column: 1 / 2;
          grid-row: 1 / 3;
          height: 2rem;
          align-self: center;
        }
        .crew-name {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }
        .crew-title {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }
        img {
          display: none;
        }
        input:checked {
          ~ .crew-more__label {
            z-index: 30;
            position: absolute;
            right: 0;
            top: 0;
          }
          ~ .crew-description {
            bottom: auto;
            top: 0;
          }
        }
        .crew-description {
          // width: auto;
          z-index: 20;
          height: auto;
          max-width: none;
          bottom: auto;
          top: -100%;
          text-align: center;
          border-radius: 1rem;
          img {
            display: block;
            margin-bottom: 1rem;
            max-width: 180px;
          }
        }
      }
    }
  }
}
</style>
