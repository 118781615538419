<template>
  <div class="container">
    <MobileNav />
    <HeroSection />
    <DetailsSection />
    <TravelSection />
    <RsvpSection />
    <FaqSection />
    <CrewSection />
    <RegistrySection />
    <CheersSection />
    <ScrollNav />
    <MainNav />
  </div>
</template>

<script>
// @ is an alias to /src
import MobileNav from "@/components/MobileNav.vue";
import HeroSection from "@/components/HeroSection.vue";
import DetailsSection from "@/components/DetailsSection.vue";
import TravelSection from "@/components/TravelSection.vue";
import RsvpSection from "@/components/RsvpSection.vue";
import FaqSection from "@/components/FaqSection.vue";
import CrewSection from "@/components/CrewSection.vue";
import RegistrySection from "@/components/RegistrySection.vue";
import CheersSection from "@/components/CheersSection.vue";
import ScrollNav from "@/components/ScrollNav.vue";
import MainNav from "@/components/MainNav.vue";

export default {
  name: "HomeView",
  components: {
    MobileNav,
    HeroSection,
    DetailsSection,
    TravelSection,
    RsvpSection,
    FaqSection,
    CrewSection,
    RegistrySection,
    CheersSection,
    ScrollNav,
    MainNav,
  },
};
</script>
