<template>
  <div class="scroll-nav">
    <div class="content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      >
        <path
          d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
        />
      </svg>
    </div>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
$purple: #645a6d;
$blue: #476c89;

.scroll-nav {
  position: sticky;
  bottom: 0.5rem;
  padding-left: 1rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .content__wrapper {
    text-align: center;
    pointer-events: none;
  }
  span {
    color: $blue;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
  }
  svg {
    fill: $blue;
  }
}
</style>
