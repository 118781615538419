<template>
  <div class="mobile-nav">
    <span class="initials">T&nbsp;&nbsp;& J</span>
    <span id="mobile-toggle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
    </span>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
$purple: #645a6d;
$blue: #476c89;

.mobile-nav {
  top: 0;
  right: 0;
  position: fixed;
  z-index: 200;
  padding: 1rem 2rem;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  background-color: #fff;
  height: 56px;
  span {
    font-family: "Ms Madi";
    color: $purple;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  svg {
    justify-self: flex-end;
    stroke: $purple;
    width: 1.5rem;
    height: 1.5rem;
  }
  @media screen and (min-width: 1025px) {
    display: none;
  }
}
</style>
