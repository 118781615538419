<template>
  <section class="section" id="registry">
    <div class="section__wrapper">
      <div class="content__wrapper">
        <div class="content">
          <h2>Our Registry</h2>
        </div>
      </div>
      <a
        class="zola-registry-embed"
        href="www.zola.com/wedding/josephandtess"
        data-registry-key="josephandtess"
        >Our Zola Wedding Registry</a
      >
      <component :is="'script'">
        !function(e,t,n){var
        s,a=e.getElementsByTagName(t)[0];e.getElementById(n)||(s=e.createElement(t),s.id=n,s.async=!0,s.src="https://widget.zola.com/js/widget.js",a.parentNode.insertBefore(s,a))}(document,"script","zola-wjs");
      </component>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  .section__wrapper {
    flex-direction: column;
    .content__wrapper {
      text-align: center;
      width: 100%;
      @media screen and (min-width: 768px) {
        padding-top: 1rem;
      }
    }
  }
}
</style>
